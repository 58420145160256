import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Group, ResponseData, UnsavedGroup } from "../../../../../models";
import HubService from "../../../../../services/hub.service";
import NumberInput from "../../shared/fields/numberInput";
import TextareaInput from "../../shared/fields/textareaInput";
import TextInput from "../../shared/fields/textInput";
import ToggleInput from "../../shared/fields/toggleInput";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";
import { putGroup } from "../../../../../services/put.data.service";
import { postGroup } from "../../../../../services/post.data.service";
import SelectInput from "../../shared/fields/selectInput";
import { getActionClassifyEmail, getActionExtractAccountData, getActionExtractTransferFormData } from "../../../../../services/get.data.service";
import { SelectOption } from "../../../../../models/selectOption";

type Props = {
  isDuplicate: (fieldToCheck: keyof Group, value: string)=> boolean;
  existingGroup?: Group;
  hub: HubService;
};

const GroupForm: FC<Props> = ({ isDuplicate, existingGroup }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [actionClassifyEmailOptions, setActionClassifyEmailOptions] = useState<SelectOption[]>([]);
  const [actionExtractAccountDataOptions, setActionExtractAccountDataOptions] = useState<SelectOption[]>([]);
  const [actionExtractTransferFormDataOptions, setactionExtractTransferFormDataOptions] = useState<SelectOption[]>([]);
  const [groupCode, setGroupCode] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [acknowledgementEmailBody, setAcknowledgementEmailBody] = useState<string>("");
  const [sendEmailFooterText, setSendEmailFooterText] = useState<string>("");
  const [suppressAcknowledgement, setSuppressAcknowledgement] = useState<boolean>(true);
  const [sendToStudio, setSendToStudio] = useState<boolean>(true);
  const [numberOfDaysToKeepClosedCases, setNumberOfDaysToKeepClosedCases] = useState<number>(0);
  const [studioUrl, setStudioUrl] = useState<string>("");
  const [studioClientId, setStudioClientId] = useState<string>("");
  const [sendImagesToStudio, setSendImagesToStudio] = useState<boolean>(false);
  const [studioApiVersion, setStudioApiVersion] = useState<number>(2);
  const [actionClassifyEmailId, setActionClassifyEmailId] = useState<number | null>(null);
  const [actionExtractAccountDataId, setActionExtractAccountDataId] = useState<number | null>(null);
  const [actionExtractTransferFormDataId, setActionExtractTransferFormDataId] = useState<number | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (existingGroup) {
      setGroupCode(existingGroup.groupCode);
      setBusinessName(existingGroup.businessName);
      setAcknowledgementEmailBody(existingGroup.acknowledgementEmailBody.replaceAll("<br />", "\n"));
      setSendEmailFooterText(existingGroup.sendEmailFooterText.replaceAll("<br />", "\n"));
      setSendToStudio(existingGroup.sendToStudio);
      setSuppressAcknowledgement(existingGroup.suppressAcknowledgement);
      setNumberOfDaysToKeepClosedCases(existingGroup.numberOfDaysToKeepClosedCases);
      setStudioUrl(existingGroup.studioUrl);
      setStudioClientId(existingGroup.studioClientId);
      setSendImagesToStudio(existingGroup.sendImagesToStudio)
      setStudioApiVersion(existingGroup.studioApiVersion)
      setActionClassifyEmailId(existingGroup.actionClassifyEmailId);
      setActionExtractAccountDataId(existingGroup.actionExtractAccountDataId);
      setActionExtractTransferFormDataId(existingGroup.actionExtractTransferFormDataId);
    }
    getActionClassifyEmail().then((response) => {
      setActionClassifyEmailOptions(
        response.data
      );
    });
    getActionExtractAccountData().then((response) => {
      setActionExtractAccountDataOptions(
        response.data
      );
    });
    getActionExtractTransferFormData().then((response) => {
      setactionExtractTransferFormDataOptions(
        response.data
      );
    });
  }, [existingGroup]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (groupCode.length === 0) {
      errorMessages.push("Group Code is required");
    } else {
      if (isDuplicate("groupCode", groupCode)) {
        errorMessages.push("Group Code has already been used");
      }
    }
    if (businessName.length === 0) {
      errorMessages.push("Business Name is required");
    } else {
      if (isDuplicate("businessName", businessName)) {
        errorMessages.push("Business Name has already been used");
      }
    }
    if (!suppressAcknowledgement && acknowledgementEmailBody.length === 0) {
      errorMessages.push("Acknowledgement Email Body Text is required when Suppress ACK is off");
    }
    if (sendToStudio) {
      if (!studioUrl) {
        errorMessages.push("Studio Url is required when Send to Studio is on");
      } else {
        try {
          const url = new URL(studioUrl);
          if (url.protocol !== "http:" && url.protocol !== "https:") {
            errorMessages.push("Studio Url is not an acceptable URL");
          }
        } catch(err) {
          errorMessages.push("Studio Url is not a valid URL");
        }
      }
      if (!studioClientId) {
        errorMessages.push("Studio Client Id is required when Send to Studio is on");
      }
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      const toSave: UnsavedGroup = {
        groupCode: groupCode.trim(),
        businessName: businessName.trim(),
        acknowledgementEmailBody: suppressAcknowledgement ? "" : acknowledgementEmailBody.replaceAll("\n", "<br />"),
        sendEmailFooterText: suppressAcknowledgement ? "" : sendEmailFooterText.replaceAll("\n", "<br />"),
        sendToStudio,
        suppressAcknowledgement,
        numberOfDaysToKeepClosedCases,
        studioUrl,
        studioClientId,
        sendImagesToStudio,
        studioApiVersion: studioApiVersion,
        actionClassifyEmailId: sendToStudio ? null : actionClassifyEmailId,
        actionExtractAccountDataId: sendToStudio ? actionExtractAccountDataId : null,
        actionExtractTransferFormDataId,
      };
      if (existingGroup) {
        putGroup(existingGroup.groupId, toSave).then((result: ResponseData<Group>)=>{
          if (result.success && result.data) {
            setRedirect(true);
          } else {
            errorMessages.push(`Failed to update Group. ${result.message}`);
          }
        });
      } else {
        postGroup(toSave).then((result: ResponseData<Group>)=>{
          if (result.success && result.data) {
            setRedirect(true);
          } else {
            errorMessages.push(`Failed to update Group. ${result.message}`);
          }
        });
      }
    }
  };

  if (redirect) return <Redirect to="/system-settings/groups/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existingGroup ? "Edit Group" : "Create Group"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existingGroup?.groupCode} >
          {existingGroup?.businessName}
        </SettingsFormInfo>
        <div className="col-sm-7">
          <div className="content-container p-3">
            {!existingGroup && <TextInput 
              label="Group Code" 
              name="group-code" 
              onChange={setGroupCode} 
              value={groupCode} 
            />
            }
            <TextInput 
              label="Business Name" 
              name="business-name" 
              onChange={setBusinessName} 
              value={businessName} 
            />
            <hr />
            <ToggleInput
              label="Suppress ACK"
              name="suppress-ack"
              onChange={setSuppressAcknowledgement}
              value={suppressAcknowledgement}
            />
            {!suppressAcknowledgement && 
              <>
                <TextareaInput               
                  label={<>
                    <span>Acknowledgement Email Body Text</span>
                    <br />
                    <small>
                      <strong>The string &lsquo;{"{caseReference}"}&rsquo; will be replaced by the actual Case Reference</strong>
                    </small>
                  </>} 
                  name="acknowledgement-email-body-test" 
                  onChange={setAcknowledgementEmailBody} 
                  rows={8}
                  value={acknowledgementEmailBody} 
                />
                <TextareaInput 
                  label="Send Email Footer Text" 
                  name="send-email-footer-text" 
                  onChange={setSendEmailFooterText} 
                  value={sendEmailFooterText} 
                />
              </>
            }
            <hr />
            {!sendToStudio && actionClassifyEmailOptions.length > 0 &&
              <>
                <SelectInput
                  label="Action: Classify Emails for:"
                  name="action-classify-email"
                  onChange={setActionClassifyEmailId}
                  id={actionClassifyEmailId}
                  options={actionClassifyEmailOptions}
                  nullOption="Don't classify emails"
                />
                <hr />
              </>
            }
            <ToggleInput
              label="Send To Studio"
              name="send-to-studio"
              onChange={setSendToStudio}
              value={sendToStudio}
            />
            {sendToStudio && 
              <>
                <TextInput 
                  label="Studio Url e.g. https://vanguard.appway.com/secure/api/AwIndexingTriaging/integration/v1/triage" 
                  name="studio-url" 
                  onChange={setStudioUrl} 
                  value={studioUrl} 
                />
                <TextInput 
                  label="Studio Client Id" 
                  name="studio-client-id" 
                  onChange={setStudioClientId} 
                  value={studioClientId} 
                />
                <ToggleInput
                  label="Send Images To Studio"
                  name="send-images-to-studio"
                  onChange={(state: boolean)=> {
                    setSendImagesToStudio(state);
                  }}
                  value={sendImagesToStudio}
                />
                <SelectInput
                  label="Studio API Version"
                  name="studio-api-version"
                  onChange={(id: number|null)=>id && setStudioApiVersion(id)}
                  id={studioApiVersion}
                  options={[
                    {id: 1, label: "Version 1"}, 
                    {id: 2, label: "Version 2"}
                  ]}
                  notNull
                />
                <SelectInput
                  label="Extract Account Data for Platform:"
                  name="action-extract-account-data"
                  onChange={setActionExtractAccountDataId}
                  id={actionExtractAccountDataId}
                  options={actionExtractAccountDataOptions}
                  nullOption="Don't extract account data"
                />
              </>
            }
            <hr />
            {actionExtractTransferFormDataOptions.length > 0 &&
              <SelectInput
                label="Extract Transfer Form Data for Client:"
                name="action-extract-transfer-form-data"
                onChange={setActionExtractTransferFormDataId}
                id={actionExtractTransferFormDataId}
                options={actionExtractTransferFormDataOptions}
                nullOption="Don't extract transfer form data"
              />
            }
            <NumberInput
              label="Number of Days to Keep Closed Cases (0 means no cases will be deleted)"
              name="number-of-days"
              onChange={(value: number):void => {if (value > -1) setNumberOfDaysToKeepClosedCases(value)}}
              value={numberOfDaysToKeepClosedCases}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default GroupForm;
