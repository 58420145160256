/* eslint-disable max-lines */
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { PollingAddress } from "../../../../../models/pollingAddress";
import { getACDs, getGroups } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import { emailRegEx } from "../../../../shared/constants";
import DateInput from "../../shared/fields/dateInput";
import EmailInput from "../../shared/fields/emailInput";
import SelectInput from "../../shared/fields/selectInput";
import TextInput from "../../shared/fields/textInput";
import ToggleInput from "../../shared/fields/toggleInput";
import sendSetting from "../../shared/helpers/sendSetting";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";
import { SelectOption } from "../../../../../models/selectOption";

type Props = {
  isDuplicate: (value: string)=> boolean;
  existingPollingAddress?: PollingAddress;
  hub: HubService;
};

const PollingAddressForm: FC<Props> = ({ isDuplicate, existingPollingAddress: existing, hub }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<SelectOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>([]);
  const [activelyPolling, setActivelyPolling] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [clientId, setClientId] = useState<number | null>(null);
  const [groupId, setGroupId] = useState<number | null>(null);
  const [lastPolled, setLastPolled] = useState<Date>(new Date());
  const [caseRefPrefix, setCaseRefPrefix] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (existing) {
      setActivelyPolling(existing.activelyPolling);
      setEmailAddress(existing.emailAddress);
      setClientId(existing.clientId);
      setGroupId(existing.group.groupId);
      setLastPolled(
        // correct bad dates in the DB
        existing.lastPolled && existing.lastPolled > "2020-01-01T00:00:00"
          ? new Date(existing.lastPolled)
          : new Date()
      );
      setCaseRefPrefix(existing.caseRefPrefix);
    }
    getACDs().then((clients) => {
      setClientOptions(
        clients.data.map((client) => {
          return {
            id: client.clientId,
            label: client.clientName,
          };
        })
      );
    });
    getGroups().then((groups) => {
      setGroupOptions(
        groups.data.map((group) => {
          return {
            id: group.groupId,
            label: group.businessName,
          };
        })
      );
    });
  }, [existing]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (emailAddress.trim().length === 0) {
      errorMessages.push("Email Address is required");
    } else if (!emailRegEx.test(emailAddress.trim())){
        errorMessages.push("Email is not a valid address");
    } else if (isDuplicate(emailAddress.trim())) {
        errorMessages.push("Email Address has already been used");      
    }
    if (groupId === null) {
      errorMessages.push("Group is required");
    }
    if (caseRefPrefix === null || !caseRefPrefix.match(/^[A-Z]{2}$/)) {
      errorMessages.push("Case Reference Prefix must be 2 letters");
    }
    return errorMessages;
  };
  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      if (existing) {
        sendSetting(hub, {
            type: "UpdatePollingAddress",
            payload: {
              emailAccountId: existing.emailAccountId,
              activelyPolling,
              emailAddress: emailAddress.trim(),
              clientId: clientId as number,
              groupId: groupId as number,
              lastPolled: lastPolled,
              caseRefPrefix: caseRefPrefix,
            }
          }, setRedirect);
      } else {
        sendSetting(hub, {
            type: "AddPollingAddress",
            payload: {
              activelyPolling,
              emailAddress: emailAddress.trim(),
              clientId: clientId as number,
              groupId: groupId as number,
              caseRefPrefix: caseRefPrefix,
            }
          }, setRedirect);
      }
    }
  };

  if (redirect) return <Redirect to="/system-settings/emailaccounts/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existing ? "Edit Email Account" : "Create Email Account"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existing?.emailAddress}>
          <p>
            LastPolled: {existing?.lastPolled 
              ? moment(lastPolled).format("DD/MM/YYYY HH:mm") 
              : "none"}
          </p>
          <p>Actively polling: {existing?.activelyPolling ? "Yes" : "No"}</p>
        </SettingsFormInfo>
        
        <div className="col-sm-7">
          <div className="content-container p-3">
            <ToggleInput
              label="Actively Polling"
              name="actively-polling"
              onChange={setActivelyPolling}
              value={activelyPolling}
            />
            <DateInput
              label="Last Polled"
              name="last-polled"
              onChange={(value):void|null=>value && setLastPolled(value)}
              value={lastPolled}
            />
            <EmailInput
              label="Email Address"
              name="email-address"
              onChange={setEmailAddress}
              value={emailAddress}
            />
            <TextInput
              label="Case Reference Prefix"
              name="unique-id"
              onChange={(id: string) =>{
                if (id === "" || (id.length < 3 && id.match(/^[A-Z]*$/i))) {
                  setCaseRefPrefix(id.toUpperCase());
                }
              }}
              value={caseRefPrefix}
            />
            <SelectInput
              label="Client"
              name="client-id"
              onChange={setClientId}
              id={clientId}
              options={clientOptions}
            />
            <SelectInput
              label="Group"
              name="group-id"
              onChange={setGroupId}
              id={groupId}
              options={groupOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PollingAddressForm;
